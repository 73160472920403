<template>
  <div>
    <el-card>
      <div class="subscribe_setting" v-loading="loading">
        <p>预约设置</p>
        <p>商品ID：{{ productId }}</p>
        <p>商品名称：{{ data.productName }}</p>
        <p>所属商家：{{ data.business && data.business.businessTitle }}</p>
        <el-table :data="storeList" style="width: 100%">
          <el-table-column align="center" prop="storeTitle" label="预约门店"> </el-table-column>
          <el-table-column align="center" label="商务号">
            <template slot-scope="{ row }">
              <el-tag v-if="row.bindedSubMerchant === 1">有商户号</el-tag>
              <el-tag v-else-if="businessModel !== 1" type="danger">无商户号</el-tag>
            </template>
          </el-table-column>
          <el-table-column align="center" label="区域">
            <template slot-scope="{ row }">
              <p>{{ row.areaId | formatArea }}</p>
            </template>
          </el-table-column>
          <el-table-column align="center" label="预约规则">
            <template slot-scope="{ row }">
              <p>{{ row.ruleName }}</p>
              <!-- <el-select
                placeholder="请选择"
                :value="row.bookingRuleId"
                disabled
                @change="handleSetRule($event, $index, row.storeId)"
              >
                <el-option v-for="item in ruleList" :key="item.id" :label="item.ruleName" :value="item.id"> </el-option>
              </el-select> -->
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作">
            <template slot-scope="{ row }">
              <el-button @click="handleEdit(row)" type="text" size="small" :disabled="+row.bookingRuleId === 0"
                >编辑规则</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-dialog :close-on-click-modal="false" :visible.sync="showEditRule" title="编辑规则">
        <el-date-picker
          v-model="selectDate"
          type="date"
          placeholder="选择日期"
          :picker-options="pickerOptions"
          @change="handleDateChange"
        >
        </el-date-picker>
        <div v-show="selectDateFormat">
          <p>当前日期 {{ selectDateFormat }}</p>
          <el-button type="primary" style="margin: 10px 0;" @click="createTimes">添加时间段</el-button>
          <el-table :data="selectTimes" style="width: 100%" height="300">
            <el-table-column align="center" label="时间段">
              <template slot-scope="{ row, $index }">
                <p v-if="row.id">{{ row.startTime }}~{{ row.endTime }}</p>
                <el-time-picker
                  v-else
                  is-range
                  v-model="row.timeRanges"
                  range-separator="至"
                  start-placeholder="开始时间"
                  end-placeholder="结束时间"
                  placeholder="选择时间范围"
                  :picker-options="timeOptions"
                  @change="handleSetTime($event, $index)"
                >
                </el-time-picker>
              </template>
            </el-table-column>
            <el-table-column align="center" label="剩余可约" width="100">
              <template slot-scope="{ row, $index }">
                <el-input
                  :value="row.remainingResources"
                  :min="0"
                  placeholder="请输入剩余可约"
                  @input="handleSetRemain($event, $index)"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column align="center" label="已预约">
              <template slot-scope="{ row }">
                <p>{{ row.bookedResources }}</p>
              </template>
            </el-table-column>
            <el-table-column align="center" label="操作">
              <template slot-scope="{ row, $index }">
                <el-button @click="handleDel($index)" type="text" size="small" :disabled="!!row.id">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="showEditRule = false">取 消</el-button>
          <el-button type="primary" @click="confirmBlock">确 定</el-button>
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import { formatDate } from '@/util/filter'

export default {
  data() {
    return {
      value1: [new Date(2016, 9, 10, 8, 40), new Date(2016, 9, 10, 9, 40)],
      data: {},
      storeList: [],
      loading: false,
      ruleList: [],
      showEditRule: false,
      currentDateRules: [],
      selectDate: '',
      pickerOptions: {
        //控制时间范围
        disabledDate: time => {
          let dateArr = this.currentDateRules.map(item => item.date)
          return !dateArr.includes(time.getTime())
        },
        cellClassName: time => {
          let dateArr = this.currentDateRules.map(item => item.date)
          let curDate = this.currentDateRules.find(item => item.date === time.getTime())
          let bool = curDate && curDate.timeIntervalList.some(item => item.bookedResources > 0)
          return dateArr.includes(time.getTime()) ? (bool ? 'has_use' : 'can_use') : ''
        }
      },
      timeOptions: {
        format: 'HH:mm'
      },
      selectDateFormat: '',
      selectTimes: [],
      timeData: {
        bookStatus: 0,
        bookedResources: 0,
        endTime: '',
        originalResources: 0,
        remainingResources: 0,
        startTime: ''
      },
      businessModel: ''
    }
  },
  computed: {
    businessId() {
      return this.$route.query.businessId
    },
    productId() {
      return this.$route.query.productId
    }
  },
  watch: {},
  methods: {
    loadData(storeId) {
      this.loading = true
      this.$api.product
        .product('getBookingData', {
          params: {
            storeId,
            productId: this.productId
          }
        })
        .then(res => {
          this.currentDateRules = res
          this.showEditRule = true
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
        .finally(() => {
          this.loading = false
        })
    },
    getStoreList() {
      return this.$http
        .get(`/boom-center-product-service/sysAdmin/store/all/mini?businessId=${this.businessId}`)
        .then(ress => {
          function compare(p) {
            return function(m, n) {
              var a = m[p]
              var b = n[p]
              return a - b //升序
            }
          }
          let res = ress.sort(compare('areaId'))
          this.storeList.map(item => {
            res.forEach(i => {
              if (item.storeId === i.id) {
                item.bindedSubMerchant = i.bindedSubMerchant
              }
            })
            return item
          })
          this.storeList = res.map(i => {
            const c = this.storeList.find(j => j.storeId === i.id)

            if (c) {
              return { ...c, areaId: i.areaId, isEnable: true }
            }
            return {
              ...i,
              bookingRuleId: '',
              bookingRuleName: '',
              isEnable: false
            }
          })
        })
    },
    getProductDetail(id) {
      this.loading = true
      return this.$http
        .get(`/boom-center-product-service/sysAdmin/product/${id}`)
        .then(res => {
          let { storeList } = res
          this.data = res
          this.storeList = storeList.map(item => {
            let t = this.ruleList.find(i => i.id === item.bookingRuleId)
            console.log(t)
            return {
              ...item,
              bookingRuleId: item.bookingRuleId,
              ruleName: t.ruleName
            }
          })
          this.businessModel = this.data.business.businessModel
          this.getStoreList()
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
        .finally(() => {
          this.loading = false
        })
    },
    getBookingRuleList() {
      return this.$http
        .get(`/boom-center-product-service/sysAdmin/bookingRule/all?businessId=${this.businessId}`)
        .then(res => {
          let list = res || []
          list.unshift({
            id: '0',
            ruleName: '无需预约'
          })
          this.ruleList = list
          this.getProductDetail(this.productId)
        })
    },
    handleEdit(row) {
      this.selectDate = ''
      this.selectDateFormat = ''
      this.selectTimes = []
      this.loadData(row.storeId)
    },
    handleSetRule(val, index, storeId) {
      this.storeList.forEach((item, i) => {
        if (i === index) {
          item.bookingRuleId = val
        }
      })
      this.saveRule(val, storeId)
    },
    saveRule(ruleId, storeId) {
      if (+ruleId === 0) return
      this.loading = true
      this.$api.product
        .product(
          'bookingRule',
          this.productId,
          JSON.stringify([
            {
              bookingRuleId: ruleId,
              storeId
            }
          ])
        )
        .then(res => {})
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleSetRemain(val, index) {
      this.selectTimes.forEach((item, i) => {
        if (i === index) {
          item.remainingResources = val.replace(/[^0-9]/g, '')
        }
      })
    },
    handleDateChange(val) {
      this.selectDateFormat = formatDate(val.getTime())
      this.currentDateRules.forEach(item => {
        if (item.date === val.getTime()) {
          let date = new Date(item.date),
            y = date.getFullYear(),
            m = date.getMonth() + 1,
            d = date.getDate()
          this.currentDateId = item.id
          this.selectTimes = item.timeIntervalList.map(item => {
            let st = item.startTime.split(':'),
              sh = st && st[0],
              sm = st && st[1],
              et = item.endTime.split(':'),
              eh = et && et[0],
              em = et && et[1]
            return {
              timeRanges: [new Date(y, m, d, sh, sm), new Date(y, m, d, eh, em)],
              ...item
            }
          })
        }
      })
    },
    handleDate(val) {
      if (+val < 10) {
        return `0${val}`
      } else {
        return val
      }
    },
    handleSetTime(val, index) {
      this.selectTimes.forEach((item, i) => {
        if (i === index) {
          let st = new Date(val[0]),
            sh = st && this.handleDate(st.getHours()),
            sm = st && this.handleDate(st.getMinutes()),
            et = new Date(val[1]),
            eh = et && this.handleDate(et.getHours()),
            em = et && this.handleDate(et.getMinutes())
          item.startTime = `${sh}:${sm}`
          item.endTime = `${eh}:${em}`
        }
      })
    },
    createTimes() {
      this.selectTimes.push({ ...this.timeData })
    },
    handleDel(index) {
      this.selectTimes.splice(index, 1)
    },
    confirmBlock() {
      if (
        !this.currentDateId ||
        !this.selectTimes.length ||
        this.selectTimes.some(item => item.startTime === '' || item.endTime === '')
      ) {
        this.$message.error('请填写时间段')
        return
      }
      this.loading = true
      let result = JSON.parse(JSON.stringify(this.selectTimes))
      return this.$api.product
        .product('updateResource', {
          id: this.currentDateId,
          timeIntervalList: result.map(item => {
            item.startTime = `${item.startTime}:00`
            item.endTime = `${item.endTime}:00`
            return item
          })
        })
        .then(res => {
          this.$message.success('保存成功')
          this.showEditRule = false
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  created() {
    this.getBookingRuleList()
  }
}
</script>
<style lang="less">
.can_use {
  span {
    border: 1px solid;
    color: #67c23a;
    border-radius: 50%;
  }
  &.prev-month,
  &.next-month {
    span {
      border: none;
      color: #c0c4cc;
    }
  }
}
.has_use {
  span {
    border: 1px solid;
    color: #f56c6c;
    border-radius: 50%;
  }
  &.prev-month,
  &.next-month {
    span {
      border: none;
      color: #c0c4cc;
    }
  }
}
</style>
<style lang="less" scoped>
.subscribe_setting {
  width: 100%;
}
</style>
